import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Menu, Icon, Segment, Popup, Button } from 'semantic-ui-react';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import PredefinedMessages from '../PredefinedMessages';

const TextComposer = ({ placeholder, onSendMessage }) => {
  const [openEmoji, setOpenEmoji] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const handleSelectEmoji = (emoji) => {
    setMessage((prevMessage) => prevMessage + emoji.native);
    setOpenEmoji(false);
  };

  const handleOnChange = (e) => {
    setMessage(e.target.value);
  };

  const sendMessage = () => {
    if (message !== '') {
      if (onSendMessage(message)) {
        setMessage('');
      }
    }
  };

  const handleKeyPress = (e) => {
    if (!(e.ctrlKey || e.shiftKey) && e.key === 'Enter') {
      e.preventDefault();
      sendMessage(message);
    }
  };

  const handleSelectMessage = (msg) => {
    setMessage((prevMessage) => prevMessage + msg);
    setOpen(false);
  };

  const handleSubmit = () => {
    sendMessage(message);
  };

  return (
    <Card.Content extra>
      <Form className="chat__form" onSubmit={handleSubmit}>
        <Segment attached="top" className="chat__segment">
          <textarea
            cols="300"
            rows="3"
            onChange={handleOnChange}
            value={message}
            onKeyPress={handleKeyPress}
            placeholder={placeholder}
          />
        </Segment>
        <Menu attached="bottom" className="chat__menu">
          <Menu.Item>
            <Popup
              key="emoji-picker"
              hoverable={false}
              on="click"
              onOpen={() => setOpenEmoji(true)}
              onClose={() => setOpenEmoji(false)}
              open={openEmoji}
              content={
                <Picker
                  native
                  autoFocus
                  title="Select emoji..."
                  emojiSize={18}
                  onSelect={handleSelectEmoji}
                />
              }
              trigger={
                <Button type="button" icon onClick={() => setOpenEmoji(true)}>
                  <Icon name="smile outline" />
                </Button>
              }
            />
          </Menu.Item>
          <Menu.Item>
            <Popup
              key="quick-reply-picker"
              on="click"
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              open={open}
              content={<PredefinedMessages onSelectMessage={handleSelectMessage} />}
              trigger={
                <Button type="button" icon>
                  <Icon name="comment alternate outline" />
                </Button>
              }
            />
          </Menu.Item>

          <Menu.Item position="right">
            <Button
              type="submit"
              floated="right"
              primary={!!message}
              className="chat__button"
              size={'huge'}
            >
              Send
            </Button>
          </Menu.Item>
        </Menu>
      </Form>
    </Card.Content>
  );
};

TextComposer.propTypes = {
  placeholder: PropTypes.string,
  onSendMessage: PropTypes.func
};

TextComposer.defaultProps = {
  placeholder: 'Message',
  onSendMessage: () => false
};

export default TextComposer;
