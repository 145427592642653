import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { last } from 'lodash';
import { modalsActions, chatActions } from 'actions';
import Api from 'services/Api';
import Session from '../Session';
import Username from '../Username';

const User = ({
  username,
  userRef,
  sessions,
  isModerator,
  isVIP,
  currentUserUsername,
  roomRef,
  createdAt
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleBlock = () => {
    dispatch(modalsActions.open('block', { roomRef: roomRef, userRef: username }));
  };

  const handleShowInfo = () => {
    dispatch(modalsActions.open('info', { userRef, username, sessions, createdAt }));
  };

  const handleCreditUser = () => {
    dispatch(modalsActions.open('credit', { userRef, username }));
  };

  const handlePrivateMessage = async () => {
    const now = Math.floor(Date.now() / 1000);
    const chatRoom = {
      player: username,
      createdBy: currentUserUsername,
      createdAt: now
    };

    const res = await Api.chat.privateMessage(chatRoom);
    if (res.status === 200) {
      dispatch(
        chatActions.startPrivateRoom({
          user: {
            userRef,
            username,
            sessions,
            createdAt
          },
          createdBy: currentUserUsername,
          createdAt: now
        })
      );
      history.push(`/chat/private-chat/${chatRoom.player}`);
    }
  };

  const lastSession = last(sessions);

  const DropdownTrigger = (
    <>
      {lastSession?.userAgentInfo?.userAgent && (
        <Session userAgent={lastSession?.userAgentInfo?.userAgent} />
      )}
      <Username username={username} isModerator={isModerator} isVIP={isVIP} createdAt={createdAt} />
    </>
  );

  return (
    <div className="user__content">
      {userRef && username && !isModerator && !isVIP ? (
        <Dropdown basic item trigger={DropdownTrigger}>
          <Dropdown.Menu>
            <Dropdown.Item text="Send Message" onClick={handlePrivateMessage} />
            <Dropdown.Item text="Info" onClick={handleShowInfo} />
            <Dropdown.Item text="Credit" onClick={handleCreditUser} />
            <Dropdown.Item text="Block" onClick={handleBlock} />
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        DropdownTrigger
      )}
    </div>
  );
};

User.propTypes = {
  userRef: PropTypes.string.isRequired,
  username: PropTypes.string,
  isModerator: PropTypes.bool,
  isVIP: PropTypes.bool,
  sessions: PropTypes.array,
  roomRef: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired
};

User.defaultProps = {
  username: 'Unkown user',
  isModerator: false,
  isVIP: false,
  sessions: []
};

export default User;
