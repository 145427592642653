import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Chat from '../Chat';
import Bingo from '../Bingo';
import ChatHistory from '../ChatHistory';

const Content = ({ roomsRefs, onSendMessage, onRequestToReconnect }) => (
  <Switch>
    <Route
      path="/chat/room/:roomRef"
      render={({ match }) => {
        if (roomsRefs.includes(match.params.roomRef)) {
          return (
            <Chat
              roomRef={match.params.roomRef}
              onSendMessage={onSendMessage}
              onRequestToReconnect={onRequestToReconnect}
            />
          );
        }
        return <Redirect to={`/chat/room/${roomsRefs[0]}`} />;
      }}
    />
    <Route
      path="/chat/private-chat/:roomRef"
      render={({ match }) => {
        if (roomsRefs.filter((room) => !room.private).includes(match.params.roomRef)) {
          return (
            <Chat
              isPrivate
              roomRef={match.params.roomRef}
              onSendMessage={onSendMessage}
              onRequestToReconnect={onRequestToReconnect}
            />
          );
        }
        return <Redirect to={`/chat/room/${roomsRefs[0]}`} />;
      }}
    />
    <Route exact path="/bingo" component={Bingo} />
    <Route exact path="/bingo/:id" component={Bingo} />
    <Route exact path="/chat/history" component={ChatHistory} />
    <Redirect from="*" to={`/chat/room/${roomsRefs[0]}`} />
  </Switch>
);

export default Content;
