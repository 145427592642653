import { chatActions } from 'actions';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import Api from '../../services/Api';

const ChatSideMenu = () => {
  const dispatch = useDispatch();
  const chatRoomsAllIds = useSelector((state) => state.Chat.result);
  const chatRoomsEntities = useSelector((state) => state.Chat.entities);
  const allChatEntities = chatRoomsAllIds.map((id) => ({
    ...chatRoomsEntities[id]
  }));

  const publicRooms = allChatEntities.filter((room) => !room.private);
  const privateRooms = allChatEntities.filter((room) => room.private);

  const handleRemoveRoom = async ({ ref, createdBy, createdAt }) => {
    try {
      const res = await Api.chat.closePrivate({
        player: ref,
        createdBy: createdBy,
        createdAt: createdAt
      });
      if (res) {
        dispatch(chatActions.closePrivateRoom(ref));
      }
    } catch (error) {
      if (error) {
        toast.error(`Close ${ref} private room ${error.toString()}`);
      }
    }
  };

  return (
    <>
      <Menu.Item>
        <Menu.Header>Chat rooms</Menu.Header>
        <Menu.Menu>
          {publicRooms.map((room) => (
            <NavLink key={room.ref} to={`/chat/room/${room.ref}`} className="item">
              {room.name}
            </NavLink>
          ))}
        </Menu.Menu>
      </Menu.Item>

      {privateRooms.length > 0 && (
        <Menu.Item>
          <Menu.Header>Private chats</Menu.Header>
          <Menu.Menu>
            {privateRooms.map((room) => (
              <NavLink key={room.ref} to={`/chat/private-chat/${room.ref}`} className="item">
                {room.name}
                <Icon
                  name="close"
                  onClick={(ev) => {
                    ev.preventDefault();
                    handleRemoveRoom(room);
                  }}
                />
              </NavLink>
            ))}
          </Menu.Menu>
          <Menu.Menu />
        </Menu.Item>
      )}

      <NavLink to="/chat/history" className="item" activeClassName="active">
        <Icon name="history" />
        Chat History
      </NavLink>
    </>
  );
};

export default ChatSideMenu;
