const addSubscription = (subscription) => ({
  type: 'ADD_SUBSCRIPTION',
  payload: subscription
});

const connectFailed = (error) => ({
  type: 'CONNECT_FAILED',
  payload: error
});

const clearFailedErrors = () => ({
  type: 'CLEAR_FAILED_ERRORS'
});

export const socketActions = {
  addSubscription,
  clearFailedErrors,
  connectFailed
};
