import update from 'update-immutable';

const INITIAL_STATE = {
  connectFailed: null,
  subscriptions: []
};

const socketReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_ROOMS_FULFILLED': {
      const { data } = action.payload;
      return update(state, {
        subscriptions: {
          $push: data.map((room) => `/topic/${room.ref}/chat.message`)
        }
      });
    }
    case 'START_PRIVATE_ROOM': {
      const room = action.payload;
      return update(state, {
        subscriptions: {
          $push: [`/topic/${room.player}/chat.message`]
        }
      });
    }
    case 'CLOSE_PRIVATE_ROOM': {
      const userRef = action.payload;
      const index = state.subscriptions.indexOf(userRef);

      return update(state, {
        subscriptions: {
          $splice: [[index, 1]]
        }
      });
    }
    case 'ADD_SUBSCRIPTION': {
      return update(state, {
        subscriptions: {
          $push: [action.payload]
        }
      });
    }
    case 'CONNECT_FAILED': {
      // If the payload is a string, it's an error message
      return typeof action.payload === 'string'
        ? update(state, {
            connectFailed: {
              $set: action.payload
            }
          })
        : state;
    }
    case 'CLEAR_FAILED_ERRORS': {
      return update(state, {
        connectFailed: {
          $set: null
        }
      });
    }
    default:
      return state;
  }
};

export default socketReducer;
